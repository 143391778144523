<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetchingSettingApp">
      <b-card>
        <ValidationObserver
          ref="contactForm"
          v-slot="{ handleSubmit, failed }"
        >
          <b-toast
            :visible="failed"
            title="Warning"
            variant="warning"
          >
            {{ $t('messages.invalid_form') }}
          </b-toast>
          <form
            autocomplete="off"
            @submit.prevent="handleSubmit(onSubmit)"
          >
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('contact.line_id')"
              label-for="line_id"
            >
              <b-input-group prepend="@">
                <b-input
                  id="line_id"
                  v-model="contactForm.contacts.line_id"
                  required
                >
                </b-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('contact.line_link')"
              label-for="line_link"
            >
              <b-input
                id="line_link"
                v-model="contactForm.contacts.line_link"
                required
              >
              </b-input>
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('contact.qr_code')"
              label-for="line_qr_code"
            >
              <b-form-input
                id="line_qr_code"
                v-model="contactForm.contacts.line_qr_code"
                required
              >
              </b-form-input>
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="`${$t('contact.message')} `"
              label-for="message"
            >
              <b-form-textarea
                id="message"
                v-model="contactForm.contacts.message"
                required
              ></b-form-textarea>
            </b-form-group>
            <hr />
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('contact.telegram_id')"
              label-for="telegram_id"
            >
              <b-input-group prepend="@">
                <b-input
                  id="telegram_id"
                  v-model="contactForm.contacts.telegram_id"
                >
                </b-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('contact.telegram_link')"
              label-for="telegram_link"
            >
              <b-input
                id="telegram_link"
                v-model="contactForm.contacts.telegram_link"
              />
            </b-form-group>
            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('contact.telegram_qrcode')"
              label-for="telegram_qrcode"
            >
              <b-input
                id="telegram_qrcode"
                v-model="contactForm.contacts.telegram_qrcode"
              >
              </b-input>
            </b-form-group>

            <hr />

            <b-form-group
              label-cols-sm="12"
              label-cols-lg="3"
              :label="$t('contact.verify_mode')"
              label-for="verify_mode"
            >
              <b-input
                id="verify_mode"
                v-model="contactForm.register.verify_mode"
                required
              >
              </b-input>
              <b-form-text>
                (1) Auto Verify ไม่ติด OTP <br>
                (2) Verify First : OTP ก่อนที่กรอกข้อมูล <br>
                (3) Verify After : กรอกข้อมูลก่อน OTP ( ถ้าออกจาก OTP จะติด Require : OTP )<br>
                (4) Not Required Verify : สถานะ User จะไม่ Verify อยู่ ซึ่งจะ Verify ทีหลังในระบบได้'
              </b-form-text>
            </b-form-group>
            <div class="text-right">
              <b-overlay
                :show="isUpdatingSettingApp"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.save') }}
                </b-button>
              </b-overlay>
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  page: {
    title: 'Contact Settings',
  },
  data() {
    return {
      contactForm: {
        contacts: {
          line_id: '',
          line_link: '',
          line_qr_code: '',
          message: '',
          telegram_id: '',
          telegram_link: '',
          telegram_qrcode: '',
        },
        register: {
          verify_mode: '',
        },
      },
    }
  },
  computed: {
    ...mapState({
      settingApp: (state) => state.brand.settingApp,
      isFetchingSettingApp: (state) => state.brand.isFetchingSettingApp,
      isUpdatingSettingApp: (state) => state.brand.isUpdatingSettingApp,
    }),
  },
  watch: {
    settingApp(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchSettingApp', 'updateSettingApp']),
    fetchData() {
      this.fetchSettingApp()
    },
    setData() {
      if (this.settingApp) {
        const data = JSON.parse(JSON.stringify(this.settingApp))
        this.contactForm = { ...data }
      }
    },
    onSubmit() {
      this.updateSettingApp(this.contactForm)
    },
  },
}
</script>
